import React from "react";
import { Alert, Button, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const snackClose = () => {
  return;
};

/**
 * Will display a small snackbar in the top right handle portion of the screen
 *
 * - open | boolean | required | Will open or close the snackbar
 * - message | string | optional | Text to display to the user
 * - success | boolean | optional | If true background will be green and represent a successful notification
 */
export default function SnackNotification({ open, message, success, onClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose ? onClose : snackClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {success ? (
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      ) : (
        <Alert severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      )}
    </Snackbar>
  );
}
