/**
 * @service         \ https://contacts-service.rrdevelopment.co.uk
 * @middleware      \ https://appURL/api/contact-service/clients
 * @description     \ Contacts service client express routes
 * @baseURL         \ https://contacts-service.rrdevelopment.co.uk/api/clients
 */
import config from "../config";

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ GET
 * @param  \ int
 * @return \ json
 */

const getEvents = async () => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: "/api/event",
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getEventsByUser = async (user_ref = false) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/events/indexByUserRef${
      user_ref && `?user_ref=${user_ref}`
    }`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ POST
 * @param  \ obj
 * @return \ json
 */

const showEvent = async (id) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/event/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

/**
 * Contacts Service https://appURL/api/contacts-service/clients
 * @method \ PUT
 * @param  \ obj
 * @return \ json
 */

const storeEvent = async (obj) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/event`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  // res.success = data.ok;
  // res.statusCode = data.status;

  return res;
};

const deleteEvent = async (id) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/event/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateEvent = async (id, obj) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/event/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreEvent = async (id) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/event/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const showEventV2 = async (id) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/V2/event/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getEventsByFilter = async (obj) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/V2/events/indexByFilter`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateEventV2 = async (obj) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/V2/event/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const storeEventV2 = async (obj) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/V2/event`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  // res.success = data.ok;
  // res.statusCode = data.status;

  return res;
};

const updateEventByType = async (id, obj) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: obj,
    endpoint: `/api/V2/events/updateTypeInstance/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteEventV2 = async (id) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/V2/event/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteEventByTypeInstance = async (id) => {
  const options = {
    base: "https://calendar-micro-middleware",
    params: false,
    payload: false,
    endpoint: `/api/V2/events/destroyTypeInstance/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getEvents,
  showEvent,
  deleteEvent,
  restoreEvent,
  updateEvent,
  storeEvent,
  getEventsByUser,
  getEventsByFilter,
  updateEventV2,
  storeEventV2,
  updateEventByType,
  deleteEventV2,
  deleteEventByTypeInstance,
};
