import React, { useState } from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export default function DatePicker(props) {
  const [date, setDate] = useState(dayjs(props.value, "DD/MM/YYYY"));

  const handleUpdate = (value) => {
    setDate(value);
    props.onChange({ name: props.name, value: value.format("YYYY-MM-DD") });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <MuiDatePicker
        value={date}
        onChange={handleUpdate}
        label={props.label}
        readOnly={props?.readOnly}
        size="small"
        format="YYYY-MM-DD"
        fullWidth
        slotProps={{
          textField: {
            helperText: props.error || "",
            error: props.error || false,
            size: "small",
            fullWidth: true,
          },
        }}
      />
    </LocalizationProvider>
  );
}
