import React, { createContext, useContext, useEffect, useState } from "react";

import { getResourceActionsByModule, getHardRoles } from "../api/tenantService";

const AuthContext = createContext(null);

export function AuthProviderHelper({ module_alias, children, ...rest }) {
  //props to contain custom props from root config and module name?
  //value = {...props, resourceActions}

  const [resourceActions, setResourceActions] = useState([]);
  const [hardRoles, setHardRoles] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if (module_alias) {
        const res = await getResourceActionsByModule({
          roles: [rest.auth.role_name],
          module_alias,
          app_uuid: rest.auth.app_uuid,
          allowOnly: true,
        });
        const res1 = await getHardRoles({
          app: rest.auth.app_uuid,
        });
        if (res) {
          setResourceActions(res.data);
        }
        if (res1) {
          setHardRoles(res1.data);
        }
      }
    };
    getData();
  }, []);

  const checkPermission = (resourceAction) => {
    try {
      const resourceArr = resourceAction ? resourceAction.split("_") : [];
      const action = resourceArr[0];
      const resource = resourceArr[1];
      const role = resourceArr[2];

      if (
        resourceActions &&
        Array.isArray(resourceActions) &&
        !resourceActions.some((el) => el.name.indexOf(action + "_" + resource))
      ) {
        return false;
      } else {
        const roleObj =
          hardRoles &&
          Array.isArray(hardRoles) &&
          hardRoles.find((el) => el.name === role);
        const filteredArr = roleObj
          ? hardRoles
              .filter((el) => el.sort <= roleObj.sort)
              .map((el) => action + "_" + resource + "_" + el.name)
          : [];
        return (
          resourceActions &&
          Array.isArray(resourceActions) &&
          resourceActions.some((el) => filteredArr.includes(el.name))
        );
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ ...rest, resourceActions, checkPermission }}>
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
