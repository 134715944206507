import React from "react";
import { Button } from "@mui/material";

export default function DeleteButton(props) {
  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: (theme) => theme.palette.error.main,
        "&:hover": {
          bgcolor: (theme) => theme.palette.error.main,
          opacity: 0.7,
        },
      }}
      {...props}
    ></Button>
  );
}
