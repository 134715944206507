import React from "react";
import { IconButton } from "@mui/material";
import Tour from "@mui/icons-material/Tour";

import "./tourButton.css";

export default function TourButton({ onClick, style }) {
  return (
    <div className="jump-icon">
      <IconButton
        onClick={onClick}
        sx={{ color: "#f57f17", ...style }}
        className="pulse-icon"
      >
        <Tour />
      </IconButton>
    </div>
  );
}
