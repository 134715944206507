import React from "react";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

export default function DateTimePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <MuiDateTimePicker
        value={dayjs(props.value)}
        onChange={(newValue) => props.onChange(props.name, newValue)}
        label={props.label}
        readOnly={props?.readOnly}
      />
    </LocalizationProvider>
  );
}
