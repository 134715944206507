import config from "../config";

const getNotifications = async () => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: false,
    endpoint: `/api/notifications/indexByRelevant`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getTypeNotifications = async (id, type) => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: false,
    endpoint: `/api/notifications/indexByType?reference_type=${type}&reference_id=${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

// Create the notification in the notification-service then push event to pusher via the call in the express layer
const saveNotification = async (obj) => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: obj,
    endpoint: `/api/notification`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/saveNotification`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const deleteNotification = async (notification_id) => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: false,
    endpoint: `/api/notification/${notification_id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const restoreNotification = async (notification_id) => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: false,
    endpoint: `/api/notification/${notification_id}`,
    method: "PATCH",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const markAsRead = async (notification_id, dismiss) => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: { dismiss: dismiss },
    endpoint: `/api/notifications/${notification_id}/markAsRead`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const markManyAsRead = async (ids) => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: { notifications: ids },
    endpoint: `/api/notifications/markManyAsRead`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const chatIndexByTicketArray = async (refId) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: refId,
    endpoint: `/api/chat/logs/indexByTicketArray`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const chatIndexByTicket = async (refId) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: refId,
    endpoint: `/api/chat/logs/indexByTicket/${refId}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const chatStore = async (refId) => {
  const options = {
    base: "https://notification-micro-middleware",
    params: false,
    payload: refId,
    endpoint: `/api/chat/log/message`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getCount = async (filter, identifier, unread) => {
  const options = {
    base: "https://notification-service",
    params: false,
    payload: null,
    endpoint: `/api/notifications/count${filter ? `/${filter}` : ""}${
      identifier ? `/${identifier}` : ""
    }${unread ? `/${unread}` : ""}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getNotifications,
  getTypeNotifications,
  saveNotification,
  deleteNotification,
  restoreNotification,
  markAsRead,
  markManyAsRead,
  chatIndexByTicketArray,
  chatStore,
  chatIndexByTicket,
  getCount,
};
