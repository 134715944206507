import config from "../config";

const baseUrl = "https://tenants-service";

const getResourceActionsByModule = async (obj) => {
  const options = {
    base: baseUrl,
    params: false,
    payload: obj,
    endpoint: `/apiV2/resourceActions/indexForModule`,
    method: "GET",
    localEnv: true,
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getResourceActionsByPermission = async (obj) => {
  const options = {
    base: baseUrl,
    params: false,
    payload: obj,
    endpoint: `/apiV2/resourceActions/indexForPermission`,
    method: "GET",
    localEnv: true,
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getHardRoles = async () => {
  const options = {
    base: baseUrl,
    params: false,
    payload: null,
    endpoint: `/apiV2/Tenant/getHardRoles`,
    method: "GET",
    localEnv: true,
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const storeResourceAction = async (obj) => {
  const options = {
    base: baseUrl,
    params: false,
    payload: obj,
    endpoint: `/apiV2/resourceActionTemplates/store`,
    method: "POST",
    localEnv: true,
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateResourceActions = async (id, obj) => {
  const options = {
    base: baseUrl,
    params: false,
    payload: obj,
    endpoint: `/apiV2/resourceActions/update/${id}`,
    method: "PUT",
    localEnv: true,
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const storeRole = async (obj) => {
  const options = {
    base: baseUrl,
    params: false,
    payload: obj,
    endpoint: `/apiV2/Role/store`,
    method: "POST",
    localEnv: true,
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  getResourceActionsByModule,
  getResourceActionsByPermission,
  getHardRoles,
  storeResourceAction,
  updateResourceActions,
  storeRole,
};
