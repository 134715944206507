import config from "../config";

/**
 * ========================
 *   Business Functions
 * ========================
 */

const businessIndex = async (page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/business`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const fetchCompaniesHouse = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/ch/fetchBusiness/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessIndexByDuplicates = async (page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/business/indexCompanyRegistrations`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessIndexFiltered = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/business/filter`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessIndexDistinctFields = async (name) => {
  const options = {
    base: "https://business-service",
    params: { name: name },
    payload: false,
    endpoint: `/apiV2/business/distinctFields`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessIndexMaybeDuplicates = async (params) => {
  const options = {
    base: "https://business-service",
    params: params,
    payload: false,
    endpoint: `/apiV2/business/indexMaybeDuplicates`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessShow = async (id, morphables = {}) => {
  const options = {
    base: "https://business-service",
    params: morphables,
    payload: false,
    endpoint: `/apiV2/business/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/business`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessUpdate = async (id, payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/business/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessUpdateMany = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/business/updateMany`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessMigrateDuplicate = async (primeId, dupId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/business/migrateDuplicate/${primeId}/${dupId}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessAddDuplicateFlag = async (primeId, dupId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/business/${dupId}/addDuplicateFlag/${primeId}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessRemoveDuplicateFlag = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/business/${id}/removeDuplicateFlag`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessDelete = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/business/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const businessRestore = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/business/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

/**
 * ========================
 *         Sites
 * ========================
 */

const siteIndex = async (page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/site`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const siteIndexForBusiness = async (businessId, page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/site/${businessId}/indexForBusiness`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const siteShow = async (id, morphables = {}) => {
  const options = {
    base: "https://business-service",
    params: morphables,
    payload: false,
    endpoint: `/apiV2/site/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const siteStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/site`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const siteUpdate = async (id, payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/site/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const siteDelete = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/site/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const siteRestore = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/site/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

/**
 * ========================
 *         Contacts
 * ========================
 */

const contactIndex = async (page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/contact`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const contactIndexForBusiness = async (businessId, page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/contact/${businessId}/indexForBusiness`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const contactIndexForSite = async (siteId, page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/contact/${siteId}/indexForSite`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const contactShow = async (id, morphables = {}) => {
  const options = {
    base: "https://business-service",
    params: morphables,
    payload: false,
    endpoint: `/apiV2/contact/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const contactStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/contact`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const contactUpdate = async (id, payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/contact/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const contactDelete = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/contact/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const contactRestore = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/contact/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

/**
 * ========================
 *    Actions Functions
 * ========================
 */

const actionStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/action`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

/**
 * ========================
 *     Request Functions
 * ========================
 */

const requestStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/request`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const requestDelete = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/request/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

/**
 * ========================
 *        Documents
 * ========================
 */

const documentIndex = async (page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/document`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const documentShow = async (id, morphables = {}) => {
  const options = {
    base: "https://business-service",
    params: morphables,
    payload: false,
    endpoint: `/apiV2/document/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const documentStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/document`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const documentDelete = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/document/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const documentRestore = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/document/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

/**
 * ========================
 *     Search Criteria
 * ========================
 */

const criteriaIndex = async (page, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/searchCriteria`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const criteriaShow = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/searchCriteria/${id}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const criteriaStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/searchCriteria`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const criteriaUpdate = async (id, payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/searchCriteria/${id}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const criteriaDelete = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/searchCriteria/${id}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const criteriaRestore = async (id) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/searchCriteria/${id}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

/**
 * ========================
 *      DATA SOURCES
 * ========================
 */

const dataSourceIndex = async (page = 0, perPage = 100) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/dataSource`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const dataSourceIndexLinkedBusinesses = async (
  sourceId,
  page = 0,
  perPage = 100
) => {
  const options = {
    base: "https://business-service",
    params: { page: page, perPage: perPage },
    payload: false,
    endpoint: `/apiV2/dataSource/${sourceId}/linkedBusinesses`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const dataSourceCountLinkedBusinesses = async (sourceId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/dataSource/${sourceId}/countBusinesses`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const dataSourceShow = async (sourceId, morphables = {}) => {
  const options = {
    base: "https://business-service",
    params: morphables,
    payload: false,
    endpoint: `/apiV2/dataSource/${sourceId}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const dataSourceStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/dataSource`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const dataSourceUpdate = async (sourceId, payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/dataSource/${sourceId}`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const dataSourceDelete = async (sourceId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/dataSource/${sourceId}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const dataSourceRestore = async (sourceId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/dataSource/${sourceId}/restore`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const personnelShow = async (personnelId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/personnel/${personnelId}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const importJobIndex = async (params = {}) => {
  const options = {
    base: "https://business-service",
    params: params,
    payload: false,
    endpoint: `/apiV2/import/jobs`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const importJobDeletedIndex = async (params = {}) => {
  const options = {
    base: "https://business-service",
    params: params,
    payload: false,
    endpoint: `/apiV2/import/jobs/deleted`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const importJobShow = async (jobId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/import/jobs/${jobId}`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const importJobGetCounts = async (agentId) => {
  const options = {
    base: "https://business-service",
    params: { agent_id: agentId },
    payload: false,
    endpoint: `/apiV2/import/jobs/getCounts/`,
    method: "GET",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const importJobStore = async (payload) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: payload,
    endpoint: `/apiV2/import/jobs`,
    method: "POST",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const importJobDelete = async (jobId) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/import/jobs/${jobId}`,
    method: "DELETE",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

const fetchOfficers = async (companyNumber) => {
  const options = {
    base: "https://business-service",
    params: false,
    payload: false,
    endpoint: `/apiV2/ch/${companyNumber}/officers`,
    method: "PUT",
  };

  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();
  res.ok = data.status == 200;
  res.success = res.ok;

  return res;
};

export {
  businessIndex,
  businessIndexByDuplicates,
  businessIndexFiltered,
  businessIndexDistinctFields,
  businessIndexMaybeDuplicates,
  businessShow,
  businessStore,
  businessUpdateMany,
  businessUpdate,
  businessMigrateDuplicate,
  businessAddDuplicateFlag,
  businessRemoveDuplicateFlag,
  businessDelete,
  businessRestore,
  siteIndex,
  siteIndexForBusiness,
  siteShow,
  siteStore,
  siteUpdate,
  siteDelete,
  siteRestore,
  contactIndex,
  contactIndexForBusiness,
  contactIndexForSite,
  contactShow,
  contactStore,
  contactUpdate,
  contactDelete,
  contactRestore,
  actionStore,
  requestStore,
  requestDelete,
  documentIndex,
  documentShow,
  documentStore,
  documentDelete,
  documentRestore,
  criteriaIndex,
  criteriaShow,
  criteriaStore,
  criteriaUpdate,
  criteriaDelete,
  criteriaRestore,
  dataSourceIndex,
  dataSourceIndexLinkedBusinesses,
  dataSourceCountLinkedBusinesses,
  dataSourceShow,
  dataSourceStore,
  dataSourceUpdate,
  dataSourceDelete,
  dataSourceRestore,
  fetchCompaniesHouse,
  personnelShow,
  importJobIndex,
  importJobGetCounts,
  importJobDeletedIndex,
  importJobShow,
  importJobStore,
  importJobDelete,
  fetchOfficers,
};
