import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

import { TenantServiceApi } from "@unity/components";

export default function PermissionDropdown({ value, onChange }) {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const res = await TenantServiceApi.getHardRoles();
        if (res && res.success) {
          setPermissions(res.data);
        } else {
          setPermissions([]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getPermissions();
  }, []);

  return (
    <FormControl sx={{ mt: 1, width: 200 }}>
      <InputLabel id="permission-label">Select Permission</InputLabel>
      <Select
        labelId="permission-label"
        id="permission"
        label="Select Permission"
        value={value || ""}
        onChange={onChange}
      >
        <MenuItem value={""}>None</MenuItem>
        {permissions.map((el, i) => (
          <MenuItem key={i} value={el.name}>
            {el.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
