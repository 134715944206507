import config from "../config";

const postIndex = async (base, endpoint, obj) => {
  const options = {
    base: `https://${base}`,
    params: false,
    payload: obj,
    endpoint: endpoint,
    method: "POST",
  };
  const data = await fetch(`${config.appURL}/api/proxy`, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(options),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

export {
  postIndex,
};
